@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'BickhamScriptPro3Semiold';
    src: url('../src//Bickham/BickhamScriptPro3Semiold.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  
  .font-bickham-semiold {
    font-family: 'BickhamScriptPro3Semiold', cursive;
  }
  
  @font-face {
    font-family: 'Lato';
    src: url('../src/Lato/Lato-Black.ttf') format('ttf'),
         url('../src/Lato/Lato-BlackItalic.ttf') format('ttf'),
         url('../src/Lato/Lato-Thin.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
  }
  